@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
.navbar-collapse-right{
    box-shadow: none;
    z-index: 1;
}
.navbar{
    height: 90px;
    font-family: "Kumbh Sans", Sans-serif;
    background-color: #f4f7fa !important;
}

.ml-auto{
    background-color: #f4f7fa;
}

.text-info-custom{
    font-family: "Kumbh Sans", Sans-serif;
    font-size: 22px;
    font-weight: 400;
    fill: #9E9E9E;
    color: #9E9E9E;
    margin-left: 10px;
    margin-right: 10px;
    text-align: left;
    background-color: #FF280000;
    padding: 12px 15px 12px 15px;
    cursor: pointer;
}
a:hover{
    color: black;
}

.text-info-custom:hover{
    background-color: lightgray;
}

.shop-btn{
    background-color: #E6351E;
    font-size: 28px;
    font-weight: 500;
    padding: 5px 25px;
    margin-left: 10px;
}
.shop-btn:hover{
    background-color: #CA2000;
}


@media screen and (min-width: 701px){
    .nav-logo img{
        margin-left: 100px;
    }
}