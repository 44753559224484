@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
.my-account-page {
    font-family: "Kumbh Sans", Sans-serif;
    width: 100%;
    overflow: hidden; 
    height: 100vh;
    background: #eee;  
}
body{
    background-color: white;
}

.my-account-heading {
    font-weight: 700;
    font-size: 30px;
    color: #000000;
    margin-top: 50px;
    margin-left: 10px;
}

.balance-container{
    margin: 10px;
    background: #fff;
    padding: 20px;
    border-radius: 40px;
    text-align: center;
}
.balance h2{
    font-size: 30px;
    font-weight: 700;
}
.balance svg{
    font-size: 60px;
    color: black;
}
.balance-remaining h2{
    font-size: 26px;
    font-weight: 700;
    margin-top: -10px;
}

.renewal-message p{
    font-size: 16px;
    margin-top: 1rem;
}

.outer-container{
    padding-left: 100px;
    padding-right: 100px;
}

.button-container{
    margin: 10px;
    background: #fff;
    padding: 16px;
    border-radius: 40px;
    margin-top: 15px;
}
.button-container h4{
    margin-bottom: 0px;
    text-align: left;
    color: black;
    font-size: 18px;
    font-weight: 600;
}
.button-container i {
    float: right;
    margin-top: -25px;
    font-size: 30px;
    color: #a9a7a7;
}
.button-container span{
    float: right;
    margin-top: -21px;
    font-size: 14px;
    color: #a9a7a7;
}

.add-on-container{
    display: flex;
    margin: 10px;
    background: #fff;
    padding: 30px;
    justify-content: space-between;
    border-radius: 40px;
    margin-top: 15px;
}

.add-on-container .text-container h4{
    font-weight: 700;
    text-align: start;
}

.add-on-container .text-container span{
    color: cornflowerblue;
    cursor: pointer;
}
.add-on-container .text-container p {
    margin-bottom: 0px;
}

.add-on-container .price{
    align-self: center;
}
.add-on-container .price h2{
    font-weight: 700;
    margin-bottom: 0px;
}


@media screen and (max-width: 700px) {
    .outer-container{
        padding-left: 12px;
        padding-right: 12px;
    }
}

.my-account-container h5{
    width: 100%;
    padding: 10px;
    text-align: center;
    margin-top: 30px;
}
.my-account-container span{
    width: 200px;
    border: 3px solid #CA2000ed;
    padding: 10px 30px;
    text-align: center;
    color: #E6351E;
    font-weight: 700;
    cursor: pointer;
}

.loader-center{
    position: fixed;
    left: calc(50% - 12px);
    top: 15px;
}

.contry-rates-container{
    display: block;
    margin: 30px;
    background: #fff;
    padding: 10px;
    border-radius: 20px;
    margin-top: 15px;
    width: 100%;

}
.my-addon-page {
    font-family: "Kumbh Sans", Sans-serif;
    width: 100%;
    overflow: hidden; 
    background: #eee;  
    min-height: 100vh;
}

.contry-rates-container .close-icon{
    float: right;
    padding: 1px 3px 3px 3px;
    background: white;
    border: 1px solid #eceaea;
    border-radius: 500px;
}
.contry-rates-container thead th{
    border-top: none;
}
