.flex{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
}
.flex input{
    width: 400px;
    border-radius: 12px;
    font-size: 16px;
    border: 1px solid black;
    padding: 14px;
    background: white;
}
.login-heading{
    text-align: center;
    margin-top: 150px;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
}
.login-subheading{
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}

.fonus-btn {
    margin-left: 40px;
    margin-right: 40px;
    background: #E6351E;
    font-size: 20px;
    border-radius: 50px;
    border-color: #922c88;
    color: #fff;
    width: 200px;
}

.flex-col{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
}
.go-back{
    cursor: pointer;
    margin-top: 20px;
}