@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
.number-selection-page {
    font-family: "Kumbh Sans", Sans-serif;
    width: 100%;
    overflow: hidden;   
    height: 100vh;
    background: #eee;
}
body{
    background-color: white;
}

.number-selection-heading h1 {
    font-weight: 700;
    font-size: 30px;
    color: #000000;
    margin-top: 50px;
    text-align: center;
}
.number-selection-heading i {
    float: left;
    font-size: 30px;
    color: #a9a7a7;
    margin-top: 55px;

}

.number-item-heading1 {
    grid-area: heading1;
    margin-top: 10px;
    margin-bottom: 50px;
}
.number-item-heading1 strong{
    font-weight: 700;
    font-size: 14px;
    color: var( --e-global-color-text );
}
.number-item-heading1 p{
    font-size: 14px;
    color: var( --e-global-color-text );
}

.outer-container{
    padding-left: 100px;
    padding-right: 100px;
}


@media screen and (max-width: 700px) {
    .outer-container{
        padding-left: 12px;
        padding-right: 12px;
    }
}

.number-active span{
    background-color: #E6351E;
    color: white;
}
.loader-center{
    position: fixed;
    left: calc(50% - 12px);
    top: 15px;
}

.number-container{
    margin: 10px;
    background: #fff;
    padding: 16px;
    border-radius: 40px;
    margin-top: 15px;
}

.number-container h4{
    margin-bottom: 0px;
    text-align: left;
    color: black;
    font-size: 18px;
    font-weight: 600;
}

.number-container span{
    width: 35px;
    font-size: 24px;
    color: white;
    padding: 0px 3px;
    text-align: center;
    background-color: #E6351E;
    cursor: pointer;
    float: right;
    border-radius: 54px;
    margin-top: -29px;
}