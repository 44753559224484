@import url("../../assets/fonts/feather/css/feather.css");

.pro-headw{
    color: #fff;
    border-radius: .25rem .25rem 0 0;
    padding: 15px;
    position: relative;
    background: #04a9f5;
}
.pcoded-navbar {
    display: block;
    z-index: 1029;
    position: fixed;
    transition: all 0.3s ease-in-out;
    box-shadow: 1px 0 20px 0 #3f4d67;
    width: 185px;
    height: 100%;
    top: 0;
    background: rgb(51, 52, 74);
    color: #a9b7d0;
    box-shadow: none !important;
}

.pcoded-navbar .navbar-content, .pcoded-navbar .navbar-wrapper {
    width: 100%;
    height: 100%;
}

.pcoded-navbar .header-logo {
    position: relative;
    align-items: center;
    display: inline-flex;
    float: left;
    height: 70px;
    text-align: center;
    width: 185px;
    margin-right: 0;
    padding: 1px 0;
    transition: all 0.3s ease-in-out;
}

.pcoded-navbar .pcoded-inner-navbar li > a > .pcoded-micon {
    font-size: 1rem;
    padding: 0px 5px;
    margin-right: 5px;
    border-radius: 4px;
    width: 30px;
    display: inline-flex;
    align-items: center;
    height: 30px;
    text-align: center;
}

.navbar-brand {
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

.navbar-brand {
    background: #3f4d67;
}

.bg-White {
    background: white !important;
    height: 70px !important;
}

.pcoded-navbar a {
    color: #a9b7d0;
}

.b-brand {
    display: flex;
    align-items: center;
}

.header-logo img {
    width: 100%;
}

.img-logo {
    width: 185px;
    height: 70px;
    padding: 15px 4px;
}

.pcoded-navbar .header-logo + .scroll-div {
    float: left;
    display: inline-block;
}

.pcoded-navbar .scroll-div.navbar-content {
    height: calc(100vh - 70px);
}

.pcoded-navbar .navbar-content, .pcoded-navbar .navbar-wrapper {
    width: 100%;
    height: 100%;
}

.navbar-content {
    position: relative;
}

.pcoded-navbar .pcoded-inner-navbar {
    flex-direction: column;
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.pcoded-navbar .pcoded-inner-navbar {
    flex-direction: column;
}
.pcoded-navbar ul {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
}

.pcoded-navbar .pcoded-inner-navbar li {
    position: relative;
}

.pcoded-navbar .pcoded-inner-navbar li > a {
    text-align: left;
    padding: 7px 15px;
    margin: 5px 0 0;
    display: block;
    border-radius: 0;
    position: relative;
}
.pcoded-navbar .pcoded-inner-navbar li > a > .pcoded-micon + .pcoded-mtext {
    position: absolute;
    top: 11px;
}

.pcoded-navbar .pcoded-inner-navbar li > a .pcoded-mtext {
    position: relative;
}

.nav-link {
    display: block;
    padding: .5rem 1rem;
}

.color-white {
    color: white !important;
}
.hr-nav {
    border-bottom: 1px solid white;
    margin: 0;
}

.app-navbar{
background-color:  #006699;
position: fixed;
top: 0px;
width: 100%;
z-index: 100;
}
.bg-White-app{
background-color:  white;
height: 40px;
}
.img-logo-app {
    width: 140px;
    height: 30px;
    padding: 0px 4px;
}
.app-layout .nav-link{
padding: 0.5rem 1rem !important;
}
.app-layout .navbar-nav{
flex-direction: row !important;
}
.app-layout .navbar-collapse {
    box-shadow: none;
}
.app-layout .collapse:not(.show) {
    display: none;
}
.img-logo-app-small {
    display: none ;
}
.messsageLayout .feather{
font-size: 20px;
}

@media (max-width: 767px){
    .img-logo-app-small {
        display: block;
        width: 50px;
        height: 30px;
        padding: 0px 4px;
    }
    .img-logo-app {
        display: none;
    }
    .app-layout .nav-link {
        padding: 0.5rem 0.5rem !important;
    }
    .bg-White-app{
        border-radius: 50%;
    }
    .messsageLayout .feather{
        font-size: 18px;
    }
}


.app-layout .pro-head-custom img{
width: 40px;
}

.app-layout .dropdown-menu-right-custom{
margin-right: 5px;
margin-top: 5px;
position: fixed;
top: 55px;
z-index: 100;
padding-top:0px;
}

.app-layout  .pro-body {
    padding: 0 ;
    margin-bottom: 0;
    list-style: none;
}

.app-layout .last-seen h5{
margin-top: -10px;
margin-bottom: 0px;
color:inherit;
}
.app-layout .last-seen span{
font-size: 12px;
}
.app-layout .message-navbar {
    background-color: #006699;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 100;
    padding-left: 0px;
    padding-right: 0px;
}

.app-layout .message-settings-dropdown {
    margin-right: 5px;
    margin-top: 10px;
    position: fixed;
    top: 55px;
    left: auto !important;
    right: 0px !important;

}
