.audio-container{
    margin: 10px;
    margin-top: 55px;
}
.audio-container audio{
    width: 100%;
    color: #fff;
    height: 58px;
}
.audio-container audio::-webkit-media-controls-panel {
    background-color: #fff;
}
.recorder-container audio::-webkit-media-controls-panel {
    background-color: #fff;
}
.recorder-container audio::-webkit-media-controls-toggle-closed-captions-button{
    display: none;
}

.record-btn{
    width: 100%;
    margin-top: 15px;
    text-align: center;
}

.record-btn button{
    background-color: #E6351E;
    border-radius: 60px;
    border: 1px solid #E6351E;
    color: white;
    padding: 10px 30px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
}
.recorder-container{
    width: 300px;
}

.recorder-container ._1ceqH ._3bC73 ._1YOWG {
    position: initial;
    left: auto;
    transform: none;
    bottom: 100px;
}
.recorder-container ._1ceqH ._3bC73 ._1dpop {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    background-color: #E6351E;
    z-index: 1000000000;
}

.recorder-container ._3bC73{
    text-align: center;
}

.recorder-container ._1ceqH ._1lB9c ._2N9dq{
    font-weight: 600;
    font-size: 24px;
    color: white;
}

.recorder-container ._1ceqH ._1lB9c{
    background-color: #E6351E;
}
.recorder-container ._1ceqH ._1Yplu ._37kfa{
    background-color: #E6351E;
    border: none;
}
.recorder-container ._1ceqH ._1Yplu ._2gd2_{
    background-color: white;
    border: none;
    color: #E6351E;
}
.recorder-container ._1ceqH ._2fG9h{
    padding: 0;
}
.recorder-container .cross-btn{
    font-size: 24px;
    color: #E6351E;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}

.recorder-container ._1ceqH ._qxztz ._3nQu5{
    background-color: white;
    border: none;
    color: #E6351E;
}
.recorder-container ._1ceqH ._2ACrw{
    border-color: #E6351E;
    left: 51%;
}
.recorder-container ._1ceqH ._3O0Io{
    border-color: #E6351E;
    left: 51%;
}
.recorder-container ._1ceqH ._qxztz ._1bSom{
    background-color: #E6351E;
    border: none;
    color: white;
}
.recorder-container ._1ceqH ._1Yplu ._1Pz2d:hover {
    background-color: #E6351E;
    border: none;
    color: white;
}
.recorder-container ._1ceqH ._1dpop:hover ._3wi1g{
    fill: white;
}
.recorder-container ._1ceqH ._dt3-T {
    min-height: 420px;
}
.recorder-container audio{
    width: 280px;
}

.Modal{
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 70%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 15%;
    top: 30%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
}
@media(min-width: 600px){
    .Modal{
        width: 500px;
        left: calc(50% -250px);
    }
}