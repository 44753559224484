@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
.activation-page {
    font-family: "Kumbh Sans", Sans-serif;
    
}
body{
    background-color: white;
}

.main-heading {
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    color: #000000;
    margin-top: 150px;
}

.container {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto auto;
    grid-template-areas: 
        "image heading1"
        "image heading2"
        "image input"
        "image button";
}
.item-image {
    grid-area: image;
    
}

.item-heading1 {
    grid-area: heading1;
    margin-top: 20px;
    
    
    
}
.item-heading1 strong{
    font-weight: 700;
    font-size: 18px;
    color: var( --e-global-color-text );
}
.item-heading1 p{
    font-size: 18px;
    color: var( --e-global-color-text );
}
.item-heading2 {
    grid-area: heading2;
}
.item-heading2 h3{
    color: rgb(124, 122, 122);
}
.item-input{
    grid-area: input;
    margin-top: 50px;
}
.item-input label{
    font-size: 18px;
    margin-bottom: 1px;
}
.item-input input{
    background: transparent;
    width: 100%;
    font-size: 18px;
    border: 1px solid rgb(124, 122, 122);
    padding: 10px;
}
.item-input input::-webkit-input-placeholder {
    color: darkgrey;
}
.item-button{
    grid-area: button;
    margin-top: 10px;
}
.item-button button{
    background-color: #E6351E;
    padding: 7px 33px;
    font-size: 18px;
    border: none;
    border-radius: 3px;
    color: white;
    cursor: pointer;
    width: 100%
}
.item-button button:hover{
    background-color: #CA2000;
}

.outer-container{
    padding-left: 100px;
    padding-right: 100px;
}

.info-container{
    margin-top: 50px;
}
.end-note{
    margin-top: 20px;
    margin-bottom: 80px;
}
.end-note p{
    font-size: 14px;
}

@media screen and (max-width: 700px) {
    .outer-container{
        padding-left: 12px;
        padding-right: 12px;
    }
    .item-image img{
        width: 100%;
    }
}
@media screen and (min-width: 701px){
    .item-image img{
        float: right;
    }
}
.activation-page{
    overflow: hidden;
}